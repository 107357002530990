<template>
  <div class="law" v-if="!isProcessing">
    <table class="law__table">
      <tr class="law__table__row">
        <td class="law__table__row__title">運営</td>
        <td class="law__table__row__content">
          {{ companyName }}
        </td>
      </tr>
      <tr class="law__table__row">
        <td class="law__table__row__title">運営元URL</td>
        <td class="law__table__row__content">
          <a class="footer__links__item__text" target="_blank"  rel="noopener noreferrer"
             :href="companyURL">{{ companyURL }}</a>
        </td>
      </tr>
      <tr class="law__table__row">
        <td class="law__table__row__title">問い合わせ先</td>
        <td class="law__table__row__content">
          <a class="menu__list__item__text" target="_blank" rel="noopener noreferrer" v-if="isContactURL" :href="contact">{{ contact }}</a>
          <a class="menu__list__item__text" target="_blank" v-else
             :href="'mailto:' + contact + '?subject=' + this.serviceName + 'に関するお問い合わせ'">{{ contact }}</a>
        </td>
      </tr>
      <tr class="law__table__row">
        <td class="law__table__row__title">会費</td>
        <td class="law__table__row__content">
          月額{{ price }}円（税込）
        </td>
      </tr>
      <tr class="law__table__row">
        <td class="law__table__row__title">支払方法</td>
        <td class="law__table__row__content">
          クレジットカード決済（Stripe）<br>
          <a class="footer__links__item__text" target="_blank" rel="noopener noreferrer"
             href="https://stripe.com/jp">ストライプジャパン株式会社</a>
          が提供する決済システムStripeを利用します。
        </td>
      </tr>
      <tr class="law__table__row">
        <td class="law__table__row__title">支払時期</td>
        <td class="law__table__row__content">
          入会日にクレジットカード決済より一ヶ月分の会費を支払って頂きます。<br>
          以後は毎月同日に自動決済いたします。
        </td>
      </tr>
      <tr class="law__table__row">
        <td class="law__table__row__title">返金</td>
        <td class="law__table__row__content">
          退会時にすでに支払い済みの会費は返金いたしません。
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
import url from '@/assets/lib/url'

export default {
  mixins: [url],
  name: 'law',
  async mounted () {
    const promises = []

    // 設定の基本情報が存在しない場合は取得
    const settingKey = ['companyName', 'conpanyURL', 'contact', 'serviceName']
    for (const key of settingKey) {
      if (!this[key]) promises.push(this.$store.dispatch('settingGeneral/getSettingGeneral', key))
    }

    await Promise.all(promises)

    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    },
    /**
     * @return {String} 運営の名前
     */
    companyName () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('companyName')
      return data ? data.value : null
    },
    /**
     * @return {String} 運営のURL
     */
    companyURL () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('companyURL')
      return data ? data.value : null
    },
    /**
     * @return {String} お問い合わせ先（メールアドレス or URL）
     */
    contact () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('contact')
      return data ? data.value : null
    },
    /**
     * @return {String} 表示金額
     */
    price () {
      const price = Number(process.env.VUE_APP_PRICE)
      return price.toLocaleString()
    },
    /**
     * @return {String} サービス名
     */
    serviceName () {
      const data = this.$store.getters['settingGeneral/settingGeneral']('serviceName')
      return data ? data.value : null
    },
    /**
     * @return {Boolean} お問い合わせ先がURLかどうか
     */
    isContactURL () {
      return this.isValidURL(this.contact)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/sass/color.scss";
@import "@/assets/sass/size.scss";

.law {
  padding: $padding_height $padding_width 0;
  margin-top: $header_height;
  font-size: 1.1rem;
  &__table {
    margin: 20px 5px 5px;
    &__row {
      vertical-align: top;
      &__title {
        width: 25%;
        padding: 5px;
        font-weight: bold;
      }
      &__content {
        width: 75%;
        padding: 5px;
      }
    }
  }
}
</style>
